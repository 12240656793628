var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PlexMediaLayout',{attrs:{"machine-identifier":_vm.metadata.machineIdentifier,"art":_vm.metadata.banner || _vm.metadata.art,"thumb":_vm.thumb,"title":_vm.title,"secondary-title":_vm.secondaryTitle,"subtitle":_vm.subtitle,"secondary-subtitle":_vm.secondarySubtitle,"children-header":_vm.childrenHeader,"children":_vm.children,"child-full-title":"","child-cols":_vm.childCols,"child-sm":_vm.childSm,"child-md":_vm.childMd,"child-xl":_vm.childXl},scopedSlots:_vm._u([{key:"belowImage",fn:function(){return [(_vm.isPlaying)?[_c('v-row',{staticStyle:{"max-width":"200px"}},[_c('v-col',{staticClass:"text--secondary text-subtitle-2",attrs:{"cols":"auto"}},[_vm._v(" Now playing on "+_vm._s(_vm.GET_CHOSEN_CLIENT.name)+" from "+_vm._s(_vm.server.name)+" ")])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"error"},on:{"click":_vm.PRESS_STOP}},[_c('v-icon',[_vm._v("stop")])],1)],1)],1),(!_vm.AM_I_HOST)?_c('v-btn',{attrs:{"color":"blue"},on:{"click":_vm.MANUAL_SYNC}},[_vm._v(" Manual sync ")]):_vm._e()]:[_c('v-col',[(_vm.metadata.Media.length > 1 || _vm.metadata.viewOffset)?_c('PlexMediaPlayDialog',{key:_vm.combinedKey,attrs:{"metadata":_vm.metadata},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"block":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("play_arrow")])],1)]}}],null,false,2160044590)}):_c('v-btn',{staticClass:"primary",attrs:{"block":""},on:{"click":function($event){return _vm.playMedia(_vm.metadata, 0, 0)}}},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]]},proxy:true},{key:"postTitle",fn:function(){return [_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[(
          _vm.metadata.Media && _vm.metadata.Media[0] && _vm.metadata.Media[0].videoResolution
        )?_c('v-chip',{staticClass:"mr-2",attrs:{"bottom":"","outlined":"","left":""}},[_vm._v(" "+_vm._s(_vm.metadata.Media[0].videoResolution.toUpperCase())+" ")]):_vm._e(),(_vm.metadata.contentRating)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"grey darken-2","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.metadata.contentRating)+" ")]):_vm._e(),(_vm.metadata.studio)?_c('v-chip',{attrs:{"color":"grey darken-2","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.metadata.studio)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.markWatched}},[_c('v-list-item-title',[_vm._v("Mark as played")])],1),_c('v-list-item',{attrs:{"href":'https://app.plex.tv/desktop#!/server/' +
                _vm.metadata.machineIdentifier +
                '/details?key=' +
                _vm.metadata.key,"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Open in Plex Web")])],1)],1)],1)],1)]},proxy:true},{key:"content",fn:function(){return [(_vm.metadata.summary)?_c('v-row',{staticClass:"text--primary text-body-2"},[_c('v-col',[_c('SpoilerText',{key:_vm.combinedKey,attrs:{"hide":!_vm.metadata.viewCount}},[_vm._v(" "+_vm._s(_vm.metadata.summary)+" ")])],1)],1):_vm._e(),(_vm.metadata.type === 'movie')?_c('v-row',{staticClass:"hidden-sm-and-down",attrs:{"justify":"start","align":"start"}},[(_vm.metadata.Role && _vm.metadata.Role.length)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-subheader',[_vm._v("Featuring")]),_vm._l((_vm.metadata.Role.slice(0, 6)),function(actor){return _c('div',{key:actor.tag},[_vm._v(" "+_vm._s(actor.tag)+" "),_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(actor.role)+" ")])])})],2):_vm._e(),(_vm.metadata.Director && _vm.metadata.Director.length)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-subheader',[_vm._v("Director")]),_vm._l((_vm.metadata.Director.slice(0, 3)),function(director){return _c('div',{key:director.tag},[_vm._v(" "+_vm._s(director.tag)+" ")])})],2):_vm._e(),(_vm.metadata.Producer && _vm.metadata.Producer.length)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-subheader',[_vm._v("Producers")]),_vm._l((_vm.metadata.Producer.slice(0, 3)),function(producer){return _c('div',{key:producer.tag},[_vm._v(" "+_vm._s(producer.tag)+" ")])})],2):_vm._e(),(_vm.metadata.Writer && _vm.metadata.Writer.length)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-subheader',[_vm._v("Writers")]),_vm._l((_vm.metadata.Writer.slice(0, 3)),function(writer){return _c('div',{key:writer.tag},[_vm._v(" "+_vm._s(writer.tag)+" ")])})],2):_vm._e()],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }